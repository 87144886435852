var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vuestic-widget',{staticClass:"user-edit-page",attrs:{"loading":_vm.fetching || _vm.loading}},[_c('template',{slot:"header"},[_c('div',{staticClass:"w-100 d-flex justify-content-between align-items-center"},[_c('span',{staticClass:"title"},[_vm._v("Edit User")]),_c('div',{staticClass:"d-flex justify-content-end align-items-center"})])]),_c('div',{staticClass:"userEditForm"},[_c('ValidationObserver',{ref:"userEditForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
var dirty = ref.dirty;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onFormSubmit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('text-input',{attrs:{"name":"first_name","label":"First Name","validate":"required"},model:{value:(_vm.formData.first_name),callback:function ($$v) {_vm.$set(_vm.formData, "first_name", $$v)},expression:"formData.first_name"}})],1),_c('div',{staticClass:"col-md-12"},[_c('text-input',{attrs:{"name":"last_name","label":"Last Name","validate":"required"},model:{value:(_vm.formData.last_name),callback:function ($$v) {_vm.$set(_vm.formData, "last_name", $$v)},expression:"formData.last_name"}})],1),_c('div',{staticClass:"col-md-12"},[_c('text-input',{attrs:{"type":"email","name":"email","placeholder":"Enter email","label":"Email","validate":"required|email"},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1)]),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-md-12 d-flex justify-content-center"},[_c('router-link',{staticClass:"btn btn-primary btn-danger mr-2",attrs:{"to":{name: 'admin.users.index'}}},[_c('span',[_vm._v("Back")])]),_c('button',{staticClass:"btn btn-primary",staticStyle:{"min-width":"80px"},attrs:{"disabled":invalid || _vm.processing}},[(_vm.processing)?_c('atom-spinner',{staticStyle:{"margin":"auto"},attrs:{"slot":"loading","animation-duration":1500,"size":14,"color":"#FFF"},slot:"loading"}):_c('span',[_vm._v("Update")])],1)],1)])])]}}])})],1),_c('div',{staticClass:"userPasswordForm mt-4"},[_c('ValidationObserver',{ref:"passwordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleUpdatePassword)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('text-input',{ref:"NewPassword",attrs:{"name":"NewPassword","label":"New Password","validate":"required","type":"password"},model:{value:(_vm.newPassword.password),callback:function ($$v) {_vm.$set(_vm.newPassword, "password", $$v)},expression:"newPassword.password"}})],1),_c('div',{staticClass:"col-md-6"},[_c('text-input',{attrs:{"name":"ConfirmPassword","label":"Confirm password","validate":("required|password:" + (_vm.newPassword.password)),"type":"password"},model:{value:(_vm.newPassword.password_confirmation),callback:function ($$v) {_vm.$set(_vm.newPassword, "password_confirmation", $$v)},expression:"newPassword.password_confirmation"}})],1)]),_c('div',{staticClass:"mt-2 text-center"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.processing || invalid}},[(_vm.processing)?_c('atom-spinner',{attrs:{"slot":"loading","animation-duration":1500,"size":14,"color":"#FFF"},slot:"loading"}):_c('span',[_vm._v("Update")])],1)])])]}}])})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }